import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import vs2 from "../../images/altkanduv2.jpg"
import vs3 from "../../images/altkanduv.jpg"
import vs4 from "../../images/ylakanduv.jpg"
import vs5 from "../../images/iiiiiii1.jpg"

const voldikSeinad = () => (
  <Layout>
    <SEO title="Voldikseinad" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>VOLDIKSEINAD</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/ruumijagajad/voldikuksed/">
          <div className="epo">
            <h2>Voldikuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/lootsuksed/">
          <div className="epo">
            <h2>Lõõtsuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/siirdeseinad/">
          <div className="epo">
            <h2>Siirdeseinad</h2>
          </div>
        </Link>
        <Link
          to="/ruumijagajad/voldikseinad/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo">
            <h2>Voldikseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/referentsid/">
          <div className="epo">
            <h2>Referentsid</h2>
          </div>
        </Link>
      </div>

      <div className="vs-wrapper">
        <div className="vs-text">
          <h3>Seinatüübid</h3>
          <b>LIIGENDSEINAD, ÜLAKANDVUSEGA VÕI ALAKANDVUSEGA</b>
          <p>
            Liigendseinasid on kahte tüüpi – ülakandvusega, kus elemendid
            ripuvad ülemise juhtlati sees, ja alakanduvaid, kus elemendid
            toetuvad ja liiguvad mööda alumist juhtlatti (põrandas). Mõlemal
            juhul kastatakse kandmiseks kuullaagritega rullikuid. Kumb tüüp
            sobib paremini, seda saab otsustada seina otstarbe ja vajaduse
            järgi. Ülakandvusega liigendsein* ei vaja alumist juhtlatti ning
            põrand jääb vabaks. Elemendid riputatakse ülemise lati külge ja need
            tihenduvad alt pehmete kummitihenditega. Alakandvusega liigendseinad
            liiguvad mööda alumist juhtlatti ja toetuvad kergelt ülemisele
            juhtlatile ning ei koorma seega laekonstruktsioone. Alakandvusega
            liigendsein tihendub alt pehme kummitihendiga vastu alumist
            juhtlatti.
          </p>
          <p>* saadaval ainult keskripustusega</p>
        </div>

        <div className="siirdeseinad-menu">
          <Link to="/ruumijagajad/voldikseinad/helipidavus/">
            <div className="ruumijagajad-menu-item">
              <h2>Helipidavus</h2>
            </div>
          </Link>
          <Link to="/ruumijagajad/voldikseinad/kogunemisetyybid">
            <div className="ruumijagajad-menu-item">
              <h2>Kogunemise tüübid</h2>
            </div>
          </Link>
          <Link to="/ruumijagajad/voldikseinad/tehnilisedandmed">
            <div className="ruumijagajad-menu-item">
              <h2>Tehnilised andmed</h2>
            </div>
          </Link>
        </div>
      </div>
    </div>

    <div className="vs-grid">
      <div>
        <h3>Alakandvusega liigendseinad</h3>
        <p>
          Alakandvusega liigendsein ei koorma laekonstruktsioone. Seina koormus
          lasub alumisel juhtlatil, milles rullikud liiguvad
        </p>
        <img src={vs2} alt="Altkanduv" width="150px"></img>
        <img src={vs3} alt="Altkanduv2" width="150px"></img>
      </div>

      <div>
        <h3>Ülakandvusega liigendseinad</h3>
        <p>
          Ülakandvusega sein jätab põrandapinnad vabaks. Seinaelement
          riputatakse juhtlati külge rullikuga. Ülakandvusega seinad
          tihendatakse alt lohisevate tihenditega.
        </p>
        <img src={vs4} alt="Ülaltkanduv" width="150px"></img>
        <img src={vs5} alt="Ülaltkanduv2" width="150px"></img>
      </div>
    </div>
  </Layout>
)

export default voldikSeinad
